<template>
  <v-app>
    <AppHeader />
    <AppHeader2 v-if="$route.name != 'Exam Section'"/>
    <v-divider v-if="$route.name != 'Login'"></v-divider>
    <router-view :key="$route.fullPath" v-bind:storage="sideNav" />
    <AppFooter v-if="$route.name != 'Exam Section'"/>
  </v-app>
</template>

<script>
import axios from "axios";
import AppHeader from "./components/Common/appHeader";
import AppHeader2 from "./components/Common/appHeader2";
import AppFooter from "./components/Common/appFooter";
import "./assets/styles/fonts.css";
import "./assets/styles/appStyle.css";
export default {
  name: 'App',

  components: {
    AppHeader,
    AppHeader2,
    AppFooter
  },
  computed: {
    appType() {
      // console.log(this.$store.state.userRole);
      return this.$store.state.userRole;
    },
    userId() {
      return this.$store.state.userId
    }
  },
  data: () => ({
    //
    sideNav: true,

  }),
  watch: {
    userId() {
      if (!this.userId)
        this.getId()
    }
  },
  beforeMount() {
    if (typeof localStorage.getItem("token")=='string') {this.getUser()}
    if (!localStorage.getItem("userId") && !localStorage.getItem("token")) this.getId();
  },
  methods: {
    winStepper(windowData) {
      this.sideNav = windowData.sideNav;
    },
    getUser() {
      axios({
        method: "GET",
        url: "/user/profile",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status) {
            this.$store.commit("userData", response.data.user);
            this.$store.commit("changeCart", response.data.cartLength);
            // this.$store.commit("userType", response.data.data.user.role);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getId() {
      axios({
        method: "GET",
        url: "/user/guest",
      })
        .then((response) => {
          if (response.data.status) {
            this.$store.commit("userId", response.data.id);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  }
};
</script>
