import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import router from './router'
import axios from 'axios'
import store from './store'
import VueElementLoading from 'vue-element-loading'
import SnackBar from './components/Common/snackBar'
import ServerError from './components/Common/500'
import ImageLoader from './components/Common/imageLoader'
import PageLoader from './components/Common/pageLoader'
import AOS from 'aos'
import 'aos/dist/aos.css'
import OwlCarousel from 'vue-owl-carousel'
Vue.component('OwlCarousel', OwlCarousel)
import "viewerjs/dist/viewer.css";
import Viewer from "v-viewer";
Vue.use(Viewer);
import VueMasonry from 'vue-masonry-css'
Vue.use(VueMasonry);

import VueCountryCode from "vue-country-code";

Vue.use(VueCountryCode);

Vue.component('ServerError', ServerError)
Vue.component('SnackBar', SnackBar)
Vue.component('ImageLoader', ImageLoader)
Vue.component('PageLoader', PageLoader)
Vue.component('VueElementLoading', VueElementLoading)

Vue.config.productionTip = false

window.axios = require('axios')
    // axios.defaults.baseURL = 'http://192.168.48.198:3300';

// Vue.prototype.baseURL = "http://192.168.54.202:3200"
// axios.defaults.baseURL = "http://192.168.54.202:3200";
// Vue.prototype.mediaUURL = "http://192.168.54.202:3200/u/";
// Vue.prototype.mediaURL = "http://192.168.54.202:3200/wp/";
// Vue.prototype.ipURL = "http://192.168.54.202:3200";

// Vue.prototype.baseURL = "http://3.27.140.132"
// axios.defaults.baseURL = "http://3.27.140.132";
// Vue.prototype.mediaUURL = "http://3.27.140.132/file/get";
// Vue.prototype.mediaURL = "http://3.27.140.132/wp/";
// Vue.prototype.ipURL = "http://3.27.140.132";

Vue.prototype.mediaUURL = "https://api.bgfsafety.com/file/get/"
axios.defaults.baseURL = "https://api.bgfsafety.com";
Vue.prototype.mediaURL = "https://api.bgfsafety.com/wp/";
Vue.prototype.ipURL = "https://api.bgfsafety.com"



// Vue.prototype.axios = axios
new Vue({
    router,
    store,
    vuetify,
    render: h => h(App),
    mounted() {
        AOS.init()
    },
}).$mount('#app')