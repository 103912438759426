<template>
    <div>
        <v-app-bar color="#3CB043" elevation="0" height="50px" class="hidden-sm-and-down">
            <v-layout wrap justify-center>
                <v-flex xs12 sm12 md12 lg10>
                    <v-layout wrap justify-center>
                        <v-flex>
                            <router-link :to="'/'">
                                <span style="font-family: poppinsmedium; color: white; font-size: 14px;">HOME</span>
                            </router-link>
                        </v-flex>
                        &nbsp;<span style=" color: #FFFFFF;">|</span> &nbsp;
                        <v-flex>
                            <router-link :to="'/about'">
                                <span style="font-family: poppinsmedium; color: white;font-size: 14px;">ABOUT</span>
                            </router-link>
                        </v-flex>
                        &nbsp;<span style=" color: #FFFFFF;">|</span> &nbsp;
                        <v-flex v-for="(item, i) in category" :key="i" style="text-transform: uppercase;">
                            <router-link :to="'/categoryDetails?name='+ item.name + '&id=' + item._id">
                            <span style="font-family: poppinsmedium; color: white;font-size: 14px;">{{ item.name }}</span>
                            </router-link>
                             &nbsp;   &nbsp;  &nbsp;<span style=" color: #FFFFFF;">|</span>
                        </v-flex>
                         <v-flex>
                            <router-link :to="'/contact'">
                                <span style="font-family: poppinsmedium; color: white;font-size: 14px;">CONTACT US</span>
                            </router-link>
                        </v-flex>
                    </v-layout>
                </v-flex>
            </v-layout>
        </v-app-bar>
    </div>
</template>
<script>
// import AccountMenu from "./accountMenu";
import axios from "axios";
export default {
    components: {
        // AccountMenu,
    },
    data() {
        return {
            appLoading: false,
            ServerError: false,
            // sideNav: false,
            // sideNav2: false,
            user: {},
            category: [],
        };
    },
    beforeMount() {
        this.getData()
    },
    //   computed: {
    //     appUser() {
    //       return this.$store.state.userData;
    //     },
    //   },

    methods: {
        getData() {
            this.appLoading = true;
            axios({
                url: "/course/catogory/list",
                method: "GET",
                // headers: {
                //   token: localStorage.getItem("token"),
                // },
            })
                .then((response) => {
                    this.appLoading = false;
                    this.category = response.data.data;
                })
                .catch((err) => {
                    this.appLoading = false;
                    this.ServerError = true;
                    console.log(err);
                });
        },
    },
};
</script>