import Vue from 'vue'
import Router from 'vue-router'
import store from './store'
import axios from 'axios'
Vue.use(Router)
let router = new Router({
    mode: 'history',
    routes: [{
            path: '/',
            props: true,
            name: 'home',
            component: () =>
                import ('./components/Home/home'),
            meta: {
                requiresAuth: false,
                isClient: true,

            },
        },
        {
            path: '/loginForm',
            name: 'login',
            meta: {
                requiresAuth: false,
                isClient: true,
                isSeller: false,
            },
            component: () =>
                import ('./components/Login/loginForm'),
        },
        {
            path: '/signUp',
            name: 'signUp',
            meta: {
                requiresAuth: false,
                isClient: true,
                isSeller: false,
            },
            component: () =>
                import ('./components/Login/signUp'),
        },
        {
            path: '/forgotPassword',
            name: 'forgotPassword',
            meta: {
                requiresAuth: false,
                isClient: true,
                isSeller: false,
            },
            component: () =>
                import ('./components/ForgotPassword/forgotPassword'),
        },
        {
            path: '/resetPassword',
            name: 'resetPassword',
            meta: {
                requiresAuth: false,
                isClient: true,
                isSeller: false,
            },
            component: () =>
                import ('./components/ForgotPassword/resetPassword'),
        },
        {
            path: '/about',
            props: true,
            component: () =>
                import ('./components/About/about'),
            meta: {
                requiresAuth: false,
                isClient: true,

            },
        },
        {
            path: '/categoryDetails',
            name: 'Category Details',
            props: true,
            component: () =>
                import ('./components/Category/categoryDetails'),
            meta: {
                requiresAuth: false,
                isClient: true,

            },
        },
        {
            path: '/galleryFilmSafetyConsulting',
            name: 'galleryFilmSafetyConsulting',
            props: true,
            component: () =>
                import ('./components/Category/galleryFilmSafetyConsulting'),
            meta: {
                requiresAuth: false,
                isClient: true,

            },
        },
        {
            path: '/galleryBiohazardISC',
            name: 'galleryBiohazardISC',
            props: true,
            component: () =>
                import ('./components/Category/galleryBiohazardISC'),
            meta: {
                requiresAuth: false,
                isClient: true,

            },
        },
        {
            path: '/galleryHAZOPISC',
            name: 'galleryHAZOPISC',
            props: true,
            component: () =>
                import ('./components/Category/galleryHAZOPISC'),
            meta: {
                requiresAuth: false,
                isClient: true,

            },
        },
        {
            path: '/course',
            props: true,
            component: () =>
                import ('./components/Category/Courses/course'),
            meta: {
                requiresAuth: false,
                isClient: true,

            },
        },
        {
            path: '/courseDetails',
            props: true,
            component: () =>
                import ('./components/Category/Courses/courseDetails'),
            meta: {
                requiresAuth: false,
                isClient: true,

            },
        },
        {
            path: '/contact',
            props: true,
            component: () =>
                import ('./components/ContactUs/contact'),
            meta: {
                requiresAuth: false,
                isClient: true,

            },
        },
        {
            path: '/cart',
            props: true,
            component: () =>
                import ('./components/Cart/cart'),
            meta: {
                requiresAuth: false,
                isClient: true,

            },
        },
        {
            path: '/success',
            props: true,
            component: () =>
                import ('./components/Cart/Payment/success'),
            meta: {
                requiresAuth: true,
                isClient: true,

            },
        },
        {
            path: '/failure',
            props: true,
            component: () =>
                import ('./components/Cart/Payment/failure'),
            meta: {
                requiresAuth: true,
                isClient: true,

            },
        },
        {
            path: '/myCourse',
            props: true,
            component: () =>
                import ('./components/MyCourses/myCourse'),
            meta: {
                requiresAuth: true,
                isClient: true,

            },
        },
        {
            path: '/myCourseDetails',
            name: 'My Course Details',
            props: true,
            component: () =>
                import ('./components/MyCourses/myCourseDetails'),
            meta: {
                requiresAuth: true,
                isClient: true,

            },
        },
        {
            path: '/order',
            props: true,
            component: () =>
                import ('./components/Orders/order'),
            meta: {
                requiresAuth: true,
                isClient: true,

            },
        },
        {
            path: '/examSection',
            name: "Exam Section",
            props: true,
            component: () =>
                import ('./components/ExamSection/examSection'),
            meta: {
                requiresAuth: true,
                isClient: true,

            },
        },
        {
            path: '/rules',
            name: "Rules",
            props: true,
            component: () =>
                import ('./components/ExamSection/rules'),
            meta: {
                requiresAuth: true,
                isClient: true,

            },
        },
        {
            path: '/examComplete',
            name: "examComplete",
            props: true,
            component: () =>
                import ('./components/ExamSection/examComplete'),
            meta: {
                requiresAuth: true,
                isClient: true,

            },
        },
        {
            path: '/examFailed',
            name: "examFailed",
            props: true,
            component: () =>
                import ('./components/ExamSection/examFailed'),
            meta: {
                requiresAuth: true,
                isClient: true,

            },
        },
        {
            path: '/examDetails',
            name: "examDetails",
            props: true,
            component: () =>
                import ('./components/Profile/examDetails'),
            meta: {
                requiresAuth: true,
                isClient: true,

            },
        },
        {
            path: '/profile',
            props: true,
            component: () =>
                import ('./components/Profile/profile'),
            meta: {
                requiresAuth: true,
                isClient: true,

            },
        },
        {
            path: '/ServerError',
            name: 'ServerError',
            props: true,
            component: () =>
                import ('./components/Common/500'),
            meta: {
                requiresAuth: false,
                isClient: true,
                isSeller: true,
                isBrand: true,
                isAffiliate: true,

            }
        },
        {
            path: '*',
            name: '404PageNotFound',
            props: true,
            component: () =>
                import ('./components/Common/404'),
            meta: {
                requiresAuth: false,
                isClient: true,
                isSeller: true,
                isBrand: true,
                isAffiliate: true,

            }
        },

    ],
    scrollBehavior() {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0;
    },
})
router.beforeEach((to, from, next) => {

    if ((to.fullPath !== "/loginForm" && to.fullPath !== "/signUp" && to.name !== 'forgotPassword' && to.name !== "resetPassword")) {
        localStorage.setItem("routeKey", to.fullPath)
    }
    // if ((to.name == 'forgotPassword') && (to.name == 'resetPassword')) {
    //     next({ name: "home" })
    //     return
    // }
    if (
        to.matched.some((route) => route.name == "login") &&
        store.state.isLoggedIn
    ) {
        next({ name: "home" });
        return;
    }
    if (store.state.isLoggedIn == true || to.matched.some(route => route.meta.requiresAuth == true)) {
        axios({
                method: 'POST',
                url: '/validate/token',
                headers: {
                    token: localStorage.getItem('token')
                }
            }).then(response => {
                if (!response.data.status) {
                    store.commit("sessionOut", true)
                    return
                }
            })
            .catch(err => {
                var msg = err;
                console.log(msg)
            });
    }
    if ((to.name == 'login') && store.state.isLoggedIn == true) {
        next({ name: 'home' })
        return
    }
    if ((to.name == 'signUp') && store.state.isLoggedIn == true) {
        next({ name: 'home' })
        return
    }
    // if ((to.name == 'examComplete') && store.state.isLoggedIn == true) {
    //     next({ name: 'home' })
    //     return
    // }
    next()
    if (to.name == 'login' && store.state.isLoggedIn == true && (store.state.userType == 'User') && (store.state.userType == 'Institution')) {
        next({ name: 'Home' })
        return
    }
    if (to.matched.some(route => route.meta.isClient == false) && store.state.isLoggedIn == true && (store.state.userType == 'User') && (store.state.userType == 'Institution')) {
        next({ name: 'Home' })
        return
    }
    next()
})

export default router