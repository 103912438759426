<template>
  <div>
    <v-app-bar color="#ffffff" elevation="0" height="85px">
      <v-layout wrap justify-center pt-0 pb-1>
        <v-flex xs12 sm10>
          <v-layout wrap justify-center>
            <v-flex xs8 sm3 md2 text-left class="hidden-sm-and-down">
              <router-link :to="'/'">
                <v-img
                  contain
                  height="80px"
                  src="./../../assets/Logo/newlogo.png"
                >
                </v-img>
              </router-link>
            </v-flex>
            <v-flex xs10 sm10 md2 class="hidden-md-and-up">
              <router-link :to="'/'">
                <v-img contain height="50px" src="./../../assets/Logo/newlogo.png">
                </v-img>
              </router-link>
            </v-flex>
            <v-flex xs2 sm1 text-right class="hidden-md-and-up">
              <v-layout wrap justify-end>
                <v-app-bar-nav-icon @click.native="sideNav2 = !sideNav2">
                  <v-icon color="#000000" size="25">mdi-menu</v-icon>
                </v-app-bar-nav-icon>
              </v-layout></v-flex
            >
            <v-flex
              xs12
              sm9
              md10
              text-right
              align-self-center
              class="hidden-sm-and-down"
            >
              <v-layout wrap justify-end>
                <!-- <v-flex xs12 sm6 md4 pt-2 v-if="$route.name == 'Category Details' &&
                  $route.query.name == 'Industry Safety Training' || $route.query.name == 'Film Safety Training'">
                  <span style="font-family: poppinsregular; color: black">BGF Safety Training Academy Login</span>
                </v-flex> -->
                <!-- <v-flex xs12 sm6 md6 lg6 pt-2 pr-3 text-right v-if="!appLogin"> -->
                <!-- <router-link :to="'/loginForm'"> -->
                <!-- <span style="font-family: poppinsregular; color: black"
                      >BGF Safety Training Academy </span
                    > -->
                <!-- </router-link> -->
                <!-- </v-flex> -->
                <!-- <v-flex xs12 sm6 md3 lg3 pr-5 text-center v-if="!appLogin">
                  <v-btn dark color="#3CB043" to="/loginForm">
                    <span
                      style="font-family: poppinsregular; text-transform: none"
                      >Student Academy Login</span
                    ></v-btn
                  >
                </v-flex> -->
                <!-- <v-flex xs12 sm6 md2 lg2 text-center v-if="!appLogin">
                  <v-btn dark color="#3CB043" to="/signUp">
                    <span
                      style="font-family: poppinsregular; text-transform: none"
                      >Sign Up</span
                    ></v-btn
                  >
                </v-flex> -->
                <!-- <v-flex xs1 sm1 md1 lg1 align-self-end v-if="appLogin">
                  <v-icon color="black">mdi-account-circle</v-icon>
                </v-flex> -->
                <v-flex
                  xs12
                  sm4
                  md4
                  lg4
                  text-right
                  align-self-end
                  v-if="appLogin"
                >
                  <span
                    style="font-family: poppinsmedium; text-transform: none"
                    >{{ appUser.name }}</span
                  >
                  <v-menu bottom offset-y transition="scale-transition">
                    <template v-slot:activator="{ on }">
                      <v-icon color="#000" v-on="on">mdi-menu-down</v-icon>
                    </template>

                    <v-layout wrap justify-center>
                      <v-flex xs12>
                        <v-card width="150px">
                          <v-layout wrap justify-center py-6>
                            <v-flex xs12 sm12 pb-2>
                              <router-link to="/myCourse">
                                <span
                                  style="
                                    font-family: poppinsmedium;
                                    text-transform: none;
                                    cursor: pointer;
                                    color: #000;
                                  "
                                  >My Courses</span
                                >
                              </router-link>
                            </v-flex>
                            <!-- <v-flex xs12 sm12 pb-2>
                              <router-link to="/order">
                                <span
                                  style="
                                    font-family: poppinsmedium;
                                    text-transform: none;
                                    cursor: pointer;
                                    color: #000;
                                  "
                                  >My Orders</span
                                >
                              </router-link>
                            </v-flex> -->
                            <v-flex xs12 sm12 pb-2>
                              <router-link to="/profile">
                                <span
                                  style="
                                    font-family: poppinsmedium;
                                    text-transform: none;
                                    cursor: pointer;
                                    color: #000;
                                  "
                                  >Profile</span
                                >
                              </router-link>
                            </v-flex>
                            <v-flex xs12 sm12>
                              <span
                                @click="appLogout()"
                                style="
                                  font-family: poppinsmedium;
                                  text-transform: none;
                                  cursor: pointer;
                                "
                                >Logout</span
                              >
                            </v-flex>
                          </v-layout>
                        </v-card>
                      </v-flex>
                    </v-layout>
                  </v-menu>
                </v-flex>
                <!--<v-flex xs1 sm1 md1 pt-1 pl-3 text-left>
                  <router-link style="text-decoration: none" :to="'/cart'">
                    <v-badge
                      color="#3CB043"
                      :content="appCart ? appCart : '0'"
                      right
                      small
                      overlap
                      offset-x="5"
                    >
                      <v-icon color="black">mdi-cart</v-icon>
                    </v-badge>
                  </router-link>
                </v-flex>-->
              </v-layout>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-app-bar>
    <v-navigation-drawer
      v-model="sideNav2"
      fixed
      temporary
      class="hidden-md-and-up"
      right
    >
      <v-layout wrap justify-center>
        <v-flex xs12 text-left>
          <v-layout wrap justify-start pa-2 pt-4 pl-4 align-self-center>
            <v-flex xs12>
              <v-layout wrap justify->
                <v-flex xs12 pt-4>
                  <router-link :to="'/'">
                    <span
                      style="
                        font-family: poppinsmedium;
                        color: black;
                        font-size: 14px;
                      "
                      >Home</span
                    >
                  </router-link>
                </v-flex>
                <v-flex xs12 pt-2>
                  <v-divider color="#000"></v-divider>
                </v-flex>
                <v-flex xs12 pt-4>
                  <router-link :to="'/about'">
                    <span
                      style="
                        font-family: poppinsmedium;
                        color: black;
                        font-size: 14px;
                      "
                      >About</span
                    >
                  </router-link>
                </v-flex>
                <v-flex xs12 pt-2>
                  <v-divider color="#000"></v-divider>
                </v-flex>
                <v-flex xs12 v-for="(item, i) in category" :key="i" pt-4>
                  <router-link
                    :to="
                      '/categoryDetails?name=' + item.name + '&id=' + item._id
                    "
                  >
                    <span
                      style="
                        font-family: poppinsmedium;
                        color: black;
                        font-size: 14px;
                      "
                      >{{ item.name }}</span
                    >
                  </router-link>
                  <v-flex xs12 pt-2>
                    <v-divider color="#000"></v-divider>
                  </v-flex>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
      <v-layout wrap justify-center>
        <v-flex xs12 text-center pa-2 pl-4 align-self-center v-if="!appLogin">
          <router-link to="/loginForm">
            <v-layout wrap justify-center>
              <!-- <v-flex xs12 text-left v-if="$route.name == 'Category Details' &&
                  $route.query.name == 'Industry Safety Training' || $route.query.name == 'Film Safety Training'">
                <span style="
                    font-family: poppinsmedium;
                    font-size: 15px;
                    color: #000;
                    letter-spacing: 2px;
                  ">BGF Safey Training Academy Login</span>
              </v-flex> -->
              <v-flex xs12 text-left>
                <span
                  style="
                    font-family: poppinsmedium;
                    font-size: 15px;
                    color: #000;
                    letter-spacing: 2px;
                  "
                  >BGF Safety Training Academy Login</span
                >
              </v-flex>
              <v-flex xs12 pt-2>
                <v-divider color="#000"></v-divider>
              </v-flex>
            </v-layout>
          </router-link>
        </v-flex>
        <v-flex xs12 text-center pa-2 pl-4 align-self-center v-if="!appLogin">
          <router-link to="/register">
            <v-layout wrap justify-center>
              <v-flex xs12 text-left>
                <span
                  style="
                    font-family: poppinsmedium;
                    font-size: 15px;
                    color: #000;
                    letter-spacing: 2px;
                  "
                  >Register</span
                >
              </v-flex>
              <v-flex xs12 pt-2>
                <v-divider color="#000"></v-divider>
              </v-flex>
            </v-layout>
          </router-link>
        </v-flex>
        <v-flex xs12 text-center pa-2 pl-4 align-self-center>
          <router-link to="/cart">
            <v-layout wrap justify-center>
              <v-flex xs12 text-left>
                <v-icon color="black">mdi-cart</v-icon>
                <span
                  style="
                    font-family: poppinsmedium;
                    font-size: 15px;
                    color: #000;
                    letter-spacing: 2px;
                  "
                  >Cart</span
                >
              </v-flex>
              <v-flex xs12 pt-2>
                <v-divider color="#000"></v-divider>
              </v-flex>
            </v-layout>
          </router-link>
        </v-flex>
        <v-flex xs12 text-center pa-2 pl-4 align-self-center v-if="appLogin">
          <router-link to="/register">
            <v-layout wrap justify-center>
              <v-flex xs12 text-left>
                <span
                  style="
                    font-family: poppinsmedium;
                    font-size: 15px;
                    color: #000;
                    letter-spacing: 2px;
                  "
                  >Profile</span
                >
              </v-flex>
              <v-flex xs12 pt-2>
                <v-divider color="#000"></v-divider>
              </v-flex>
            </v-layout>
          </router-link>
        </v-flex>
        <v-flex xs12 text-center pa-2 pl-4 align-self-center v-if="appLogin">
          <v-layout wrap justify-center>
            <v-flex xs12 text-left @click="appLogout()">
              <span
                style="
                  font-family: poppinsmedium;
                  font-size: 15px;
                  color: #000;
                  letter-spacing: 2px;
                "
                >Logout</span
              >
            </v-flex>
            <v-flex xs12 pt-2>
              <v-divider color="#000"></v-divider>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-navigation-drawer>
  </div>
</template>
<script>
// import AccountMenu from "./accountMenu";
import axios from "axios";
export default {
  components: {
    // AccountMenu,
  },
  data() {
    return {
      hover: false,
      appLoading: false,
      ServerError: false,
      sideNav: false,
      sideNav2: false,
      sideNav3: false,
      user: {},
      userName: null,
      profilePic: null,
      cartItems: 0,
      disabledNav: true,
      searchKey: null,
      myName: [],
      category: [],
      navItems: [
        { name: "Register Asset", route: "/assetRegister", type: "link" },
        { name: "View Asset", route: "/", type: "link" },
      ],
    };
  },
  computed: {
    appUser() {
      return this.$store.state.userData;
    },
    appLogin() {
      return this.$store.state.isLoggedIn;
    },
    // appType() {
    //   return this.$store.state.userType;
    // },
    appCart() {
      if (this.$store.state.cartLength == undefined) {
        return 0;
      } else {
        return this.$store.state.cartLength;
      }
    },
  },
  watch: {
    sideNav() {
      this.$emit("stepper", {
        sideNav: this.sideNav,
      });
    },
  },
  beforeMount() {
    this.getData();
  },
  //   computed: {
  //     appUser() {
  //       return this.$store.state.userData;
  //     },
  //   },

  methods: {
    getData() {
      this.appLoading = true;
      axios({
        url: "/course/catogory/list",
        method: "GET",
        // headers: {
        //   token: localStorage.getItem("token"),
        // },
      })
        .then((response) => {
          this.appLoading = false;
          this.category = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    appLogout() {
      this.$store.commit("logoutUser", true);
      // if (this.sideNav2 == true) {
      //   this.sideNav2 = false;
      // }
    },
  },
};
</script>