<template>
    <div>
      <v-divider></v-divider>
      <v-footer padless light color="#202229">
        <v-layout wrap justify-center py-16>
          <v-flex xs12 sm10 px-2 text-center>
            <v-layout wrap justify-start>
              <v-flex xs12 sm4 md4 lg4 xl4 text-left align-self-center style="line-height: 16px">
                <router-link to="/">
                  <v-layout wrap justify-start pb-4>
                    <v-flex xs4 sm5 text-left align-self-start>
                      <v-img src="./../../assets/Logo/newlogo.png" contain></v-img>
                    </v-flex>
                  </v-layout>
                  <v-layout wrap>
                    <v-flex xs12 text-justify>
                      <span style="font-size: 14px; letter-spacing: 0px; font-family: poppinslight;  color: white;">
                        <!-- Your premier partner in safety Consulting, ISO Standards training, and film set safety. -->
                        Your premier partner in industry safety consulting and film set safety.
                      </span>
                    </v-flex>
                  </v-layout>
                </router-link>
              </v-flex>
              <v-spacer></v-spacer>
              <v-flex xs12 sm3 md3 lg3 xl2 text-left pt-10 pt-sm-0>
                <v-layout wrap justify-end>
                    <v-flex xs12 sm12 md12 pb-2 align-self-center>
                    <router-link to="/TermsAndConditions">
                      <span style="
                      font-family: poppinssemibold;
                      font-size: 15px;
                      color: #FFFFFF;
                    ">
                        Navigation 
                      </span>
                    </router-link>
                  </v-flex>
                  <v-flex xs12 sm12 md12 align-self-center>
                    <router-link to="/">
                      <span style="
                      font-family: poppinslight;
                      font-size: 13px;
                      color: #FFFFFF;
                    ">
                        Home
                      </span>
                    </router-link>
                  </v-flex>
                  <!-- <v-flex xs12 sm12 md12 align-self-center>
                    <router-link to="/loginForm">
                      <span style="
                        font-family: poppinslight;
                      font-size: 13px;
                      color: #FFFFFF;
                    ">
                      Login
                      </span>
                    </router-link>
                  </v-flex>
                  <v-flex xs12 sm12 md12 align-self-center>
                    <router-link to="/signUp">
                      <span style="
                        font-family: poppinslight;
                      font-size: 13px;
                      color: #FFFFFF;
                    ">
                       Sign Up
                      </span>
                    </router-link>
                  </v-flex> -->
                </v-layout>
              </v-flex>
              <v-flex xs12 sm3 md2 lg2 xl2 text-left pt-4 pt-sm-0>
                <v-layout wrap justify-end>
                    <v-flex xs12 sm12 md12 pb-2 align-self-center>
                    <router-link to="/about">
                      <span style="
                      font-family: poppinssemibold;
                      font-size: 15px;
                      color: #FFFFFF;
                    ">
                        About 
                      </span>
                    </router-link>
                  </v-flex>
                  <v-flex xs12 sm12 md12 align-self-center v-for="(item, i) in category" :key="i">
                    <router-link :to="'/categoryDetails?name='+ item.name + '&id=' + item._id">
                      <span style="
                      font-family: poppinslight;
                      font-size: 13px;
                      color: #FFFFFF;
                    ">
                       {{ item.name }}
                      </span>
                    </router-link>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-footer>
      <v-divider></v-divider>
      <v-footer padless light color="#202229">
        <v-layout wrap justify-center py-4>
          <v-flex xs12 sm11 px-2>
            <v-layout wrap justify-start>
              <v-flex xs12 text-center align-self-center>
                <span style="
                    font-family: poppinsmedium;
                    font-size: 15px;
                    color: #FFFFFF;
                  ">
                  &copy; BGF Safety Consulting  2023 - All rights reserved 
                </span>
                &nbsp;<span style=" color: #FFFFFF;">|</span> &nbsp;
                <span style="
                    font-family: poppinsmedium;
                    font-size: 15px;
                    color: #FFFFFF;
                  ">
                  Designed & Developed by
                     <a href="https://leopardtechlabs.com/">
                  <span style="color: #FFB631"> Leopard Tech Labs Pvt Ltd </span></a>
                </span>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-footer>
    </div>
  </template>
  <script>
  import axios from "axios";
  export default {
      data() {
          return {
              appLoading: false,
              ServerError: false,
              user: {},
              category: [],
          };
      },
      beforeMount() {
          this.getData()
      },
      methods: {
          getData() {
              this.appLoading = true;
              axios({
                  url: "/course/catogory/list",
                  method: "GET",
              })
                  .then((response) => {
                      this.appLoading = false;
                      this.category = response.data.data;
                  })
                  .catch((err) => {
                      this.appLoading = false;
                      this.ServerError = true;
                      console.log(err);
                  });
          },
      },
  };
  </script>